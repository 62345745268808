import React from "react";
import { Element } from "react-scroll";

function Contact() {
  
  function sendMail() {
    window.location.href = "mailto:aaron@aaronennis.com";
  }

  return (
    <Element name="Contact" className="element">
      <div id="contact" className="container-fluid contact">
        <div className="d-flex justify-content-center">
          <h1 className="mb-5 mt-5 highlight-text">Contact</h1>
        </div>
        <div className="container d-flex justify-content-center">
          <div className="contact-points mb-5 d-flex flex-wrap">
            <div className="email mb-2 me-5">
              <div
                onClick={sendMail}
                className="contact-link d-inline-flex align-items-center"
              >
                <span
                  className="fas fa-2x fa-envelope me-2"
                  aria-hidden="true"
                />
                <span className="email-reverse">moc.sinnenoraa@noraa</span>
              </div>
            </div>
            <div className="linkedin mb-2 me-5">
              <a
                href="https://github.com/Aaron-Ennis"
                target="_blank"
                className="contact-link d-inline-flex align-items-center"
              >
                <span
                  className="fab fa-2x fa-github me-2"
                  aria-hidden="true"
                />
                <span>github.com/Aaron-Ennis</span>
              </a>
            </div>
            <div className="linkedin mb-2">
              <a
                href="https://www.linkedin.com/in/aaronennis/"
                target="_blank"
                className="contact-link d-inline-flex align-items-center"
              >
                <span
                  className="fab fa-2x fa-linkedin me-2"
                  aria-hidden="true"
                />
                <span>linkedin.com/in/aaronennis/</span>
              </a>
            </div>

          </div>
        </div>
      </div>
    </Element>
  );
}

export default Contact;
