export const jobData = [
  {
    id: 1,
    company: "Verizon",
    jobTitle: "Principal Engineer - Application Development",
    dateRange: "Sep 2018 - Present",
    description: `I am the lead developer for Verizon's Learning Album Studio application. I work with a remote Agile team on the application's JavaScript, React, Redux, and Ruby on Rails full-stack framework. This application is used by hundreds of designers and facilitators to author and publish training programs to tens of thousands of employees.`,
  },
  {
    id: 2,
    company: "Verizon",
    jobTitle: "Consultant - Technical Project Management",
    dateRange: "Jun 2012 - Sep 2018",
    description: `I managed projects to deploy emerging technologies in the learning & development space. This included: a web-based gamified employee engagement platform for over 17,000 customer service employees; converting our training delivery teams to use tablets for digital facilitator materials rather than paper-based materials; and deploying a small fleet of remote telepresence robots to supplement physical travel.`,
  },
];
