export const programmingData = [
  "C/C++",
  "JavaScript",
  "React",
  "Dart & Flutter",
  "Express",
  "JUCE",
  "Node",
  "MySQL",
  "Bootstrap",
  "HTML5/CSS3",
  "JSON",
  "REST APIs",
];

export const toolsData = [
  "Continuous Integration",
  "Test-Driven Development",
  "Scrum",
  "Jest",
  "Linux",
  "Git Command Line",
  "GitHub",
  "Arduino Platform",
];
