import React, { Fragment } from "react";
import { Element } from "react-scroll";

import Intro from "./introduction";
import Experience from "./experience";
import Education from "./education";
import Skills from "./skills";
import Footer from "../footer";
import Contact from "../contact";
import NavbarIndex from "./navbar";

function Professional(props) {
  const components = ["Intro", "Experience", "Education", "Skills"];
  return (
    <Fragment>
      <NavbarIndex smallScreen={props.smallScreen} components={components} />
      <Intro />
      <Experience smallScreen={props.smallScreen}/>
      <Education smallScreen={props.smallScreen}/>
      <Skills />
      <Contact />
      <Footer />
    </Fragment>
  );
}

export default Professional;
