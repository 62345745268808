import React from "react";

function Footer() {
  return (
    <div id="footer" className="container-fluid  footer">
      <div className="mt-5 mb-5 d-flex justify-content-center">
        <div>
          <div className="ms-5 mb-2">Powered By</div>
          <div className="ms-5">
            <div className="powered-by-item d-inline-flex align-items-center mr-5 mb-2">
              <i className="fab fa-react mr-1" />
              <span>React</span>
            </div>
          </div>
        </div>
        <div>
          <div className="ms-5 mb-2">Design Inspired By</div>
          <div className="ms-5">
            <a
              href="http://pascalvangemert.nl/"
              className="powered-by-item d-inline-flex align-items-center mr-5 mb-2"
              target="_blank"
            >
              <i className="fas fa-link me-1" />
              <span>Pascal van Gemert</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
