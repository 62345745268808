import React, { Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

function JobItem(props) {
  const {
    jobId,
    company,
    jobTitle,
    dateRange,
    description,
    smallScreen,
  } = props;
  return (
    <Fragment>
      <div className="job-container mb-5 container row">
        <div className="col-md-4">
          <h5 className="company">{company}</h5>
          <h6 className="date-range">{dateRange}</h6>
        </div>
        <div className="col-md-8">
          {smallScreen ? (
            <Fragment>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey={jobId}>
                  <h5 className="job-title">{jobTitle}</h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={jobId}>
                  <Card.Body>{description}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Fragment>
          ) : (
            <Fragment>
              <h5 className="job-title">{jobTitle}</h5>
              <div className="description hide-small">{description}</div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default JobItem;
