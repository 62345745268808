import React, { Fragment, useState, useEffect } from "react";
import { Provider } from "react-redux";
import axios from "axios";
import { animateScroll as scroll, scroller } from "react-scroll";
import store from "../../store";

import Home from "../home";
import Professional from "../professional";

function Main(props) {
  return (
    <Provider store={store}>
      <Home />
      <Professional smallScreen={props.smallScreen} />
    </Provider>
  );
}

export default Main;
