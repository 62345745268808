import React, { Fragment } from "react";
import { Element } from "react-scroll";
import JobItem from "./components/job_item";
import Accordion from "react-bootstrap/Accordion";

import { jobData } from "./components/job_data";

function Experience(props) {
  return (
    <Element name="Experience" className="element">
      <div id="experience" className="container-fluid experience">
        <div className="d-flex justify-content-center">
          <h1 className="mb-5 mt-5 title-light">Experience</h1>
        </div>
        <div className="container">
          {props.smallScreen ? (
            <Accordion>
              {jobData.map((jobItem) => {
                return (
                  <JobItem
                    key={jobItem.id}
                    jobId={jobItem.id}
                    company={jobItem.company}
                    jobTitle={jobItem.jobTitle}
                    dateRange={jobItem.dateRange}
                    description={jobItem.description}
                    smallScreen={props.smallScreen}
                  />
                );
              })}
            </Accordion>
          ) : (
            <Fragment>
              {jobData.map((jobItem) => {
                return (
                  <JobItem
                    key={jobItem.id}
                    jobId={jobItem.id}
                    company={jobItem.company}
                    jobTitle={jobItem.jobTitle}
                    dateRange={jobItem.dateRange}
                    description={jobItem.description}
                    smallScreen={props.smallScreen}
                  />
                );
              })}
            </Fragment>
          )}
        </div>
      </div>
    </Element>
  );
}

export default Experience;
