import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { animateScroll as scroll, scroller } from "react-scroll";

function NavbarIndex(props) {
  function scrollTo(element) {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: props.smallScreen ? -300 : -50,
    });
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
      <Navbar.Toggle aria-controls="professional-navbar-nav" />
      <Navbar.Collapse id="professional-navbar-nav">
        <Nav
          onSelect={(selectedKey) => scrollTo(selectedKey)}
          className="mr-auto"
        >
          <Nav.Link eventKey="banner">Home</Nav.Link>
          {props.components &&
            props.components.map((component) => {
              return (
                <Nav.Link key={component} eventKey={component}>
                  {component}
                </Nav.Link>
              );
            })}
          <Nav.Link eventKey="contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarIndex;
