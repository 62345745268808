import React from 'react';
import { createRoot } from 'react-dom/client';
import 'bootstrap/scss/bootstrap.scss';
import './index.scss';

import Main from './app/containers/main';

const smallScreen = window.innerWidth < 768 ? true : false;
const container = document.getElementById('app');
const root = createRoot(container);
root.render(<Main smallScreen={smallScreen} />);
