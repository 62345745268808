import React, { Fragment } from "react";
import { Element } from "react-scroll";
import myAvi from "../../../assets/images/aaron_avi_sq_200.png";

function Intro() {
  return (
    <Element name="Intro" className="element">
      <div id="introduction" className="container-fluid introduction">
        <div className="intro-details text-center mt-5">
          <h1 className="highlight-text">Aaron Ennis</h1>
          <h6>Greater Seattle Area, WA, USA</h6>
        </div>
        <div className="justify-content-center m-3">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <div className="m-5">
              <img src={myAvi} alt="Aaron Ennis" className="avatar" />
            </div>
            <div className="intro-text">
              <p>
                I am a versatile programmer and software engineer. I love
                solving problems, and making systems and platforms talk to each
                other to accomplish interesting things. I have a broad skill set
                that includes: software engineering in Agile team environments;
                full-stack web development with Node, React, and JavaScript;
                object-oriented programming; and building tools and data
                structures.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default Intro;
