import React, { Fragment } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

function School(props) {
  const {
    schoolId,
    school,
    degree,
    major,
    description,
    alt,
    smallScreen,
  } = props;
  return (
    <Fragment>
      <div className="job-container mb-5 container row">
        <div className="col-md-4">
          <h5 className="school">{school}</h5>
          <h6 className="degree">{degree}</h6>
        </div>
        <div className="col-md-8">
{smallScreen ? (
            <Fragment>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey={schoolId}>
                  <h5 className="major">{major}</h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={schoolId}>
                  <Card.Body>{description}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Fragment>
          ) : (
            <Fragment>
              <h5 className="major">{major}</h5>
              <div className="description hide-small">{description}</div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default School;
