import React, { Fragment } from "react";
import { Element } from "react-scroll";
import School from "./components/school";
import Accordion from "react-bootstrap/Accordion";
import { schoolData } from "./components/school_data";

function Education(props) {
  return (
    <Element name="Education" className="element">
      <div id="education" className="container-fluid education">
        <div className="d-flex justify-content-center">
          <h1 className="mb-5 mt-5 highlight-text">Education</h1>
        </div>
        <div className="container">
          {props.smallScreen ? (
            <Accordion>
              {schoolData.map((school) => {
                return (
                  <School
                    key={school.id}
                    schoolId={school.id}
                    school={school.school}
                    logo={school.logo}
                    alt={school.alt}
                    degree={school.degree}
                    major={school.major}
                    description={school.description}
                    smallScreen={props.smallScreen}
                  />
                );
              })}
            </Accordion>
          ) : (
            <Fragment>
              {schoolData.map((school) => {
                return (
                  <School
                    key={school.id}
                    schoolId={school.id}
                    school={school.school}
                    logo={school.logo}
                    alt={school.alt}
                    degree={school.degree}
                    major={school.major}
                    description={school.description}
                    smallScreen={props.smallScreen}
                  />
                );
              })}
            </Fragment>
          )}
        </div>
      </div>
    </Element>
  );
}

export default Education;
