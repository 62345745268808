import React from "react";
import { Element, animateScroll as scroll, scroller } from "react-scroll";

function Home(props) {
  function handleClick(event) {
    props.changeScope(event);
  }

  function scrollToElement(element) {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: props.smallScreen ? -250 : -50,
    });
  }

  return (
    <Element name="Home" className="element">
      <div
        id="banner"
        className="d-flex jumbotron jumbotron-fluid align-items-center justify-content-center"
      >
        <div
          className="banner d-flex align-items-center justify-content-center"
          onClick={() => {
            scrollToElement("Intro");
          }}
        >
          <h1>Aaron Ennis</h1>
          <div className="down-caret">
            <i className="fas fa-3x fa-angle-down" />
          </div>
        </div>
      </div>
    </Element>
  );
}

export default Home;
