export const schoolData = [
  {
    id: 1,
    school: "Oregon State University",
    degree: "Bachelor of Science",
    major: "Computer Science",
    alt: "OSU School Logo",
    description: `Through my studies at OSU I have gained a solid foundation in object-oriented programming, data structures, full-stack web development, database architecture and interfaces, computer architecture, IA-32 assembly language (MASM syntax), UX design, and general software engineering principles.`,
  },
  {
    id: 2,
    school: "Washington State University",
    degree: "Bachelor of Business Administration",
    major: "Management Information Systems",
    alt: "WSU School Logo",
    description: `My studies at WSU focused on the technical aspects of managing projects and using data to make informed business decisions. This includes a solid foundation in SDLC, system analysis and design, database design and management, data analytics, and software development.`,
  },
];
