import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";

/*import JobItem from "./job_item";*/

import { programmingData, toolsData } from "./components/skills_data";

function Skills() {
  const [progData, setProgData] = useState([]);
  useEffect(() => {
    setProgData(programmingData.sort());
  });

  const [toolData, setToolData] = useState([]);
  useEffect(() => {
    setToolData(toolsData.sort());
  });

  return (
    <Element name="Skills" className="element">
      <div id="skills" className="container-fluid skills">
        <div className="d-flex justify-content-center">
          <h1 className="mt-5 title-light">Skills</h1>
        </div>
        <div className="container d-flex flex-wrap justify-content-center">
          <div className="m-5">
            <h4>Programming</h4>
            <hr />
            <ul className="skills">
              {progData.map((skill) => {
                return (
                  <li key={skill} className="skill">
                    <h6>{skill}</h6>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="m-5">
            <h4>Tools</h4>
            <hr />
            <ul className="skills">
              {toolData.map((tool) => {
                return (
                  <li key={tool} className="tool">
                    <h6>{tool}</h6>
                  </li>
                );
              })}
            </ul>
          </div>
          <div />
        </div>
      </div>
    </Element>
  );
}

export default Skills;
